<template>
  <!-- Table Container Card -->
  <b-card >
        <b-row>
          <b-col
            v-for="(itemx, indexx) in appData"
            :key="'itemlistz' + indexx"
            cols="12"
            sm="12"
            md="3"
            class="mt-1 d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <b-card-body class="border border-primary rounded p-0">
              <b-link
                :to="{
                  name: itemx.name,
                }"
                class="font-weight-bold d-block text-wrap"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar rounded size="50" variant="light-primary">
                      <feather-icon size="18" :icon="itemx.icon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="my-1">
                      {{ $t(itemx.title) }}
                    </h6>
                  </b-media-body>
                </b-media>
              </b-link>
            </b-card-body>
          </b-col>
        </b-row>
  </b-card>
</template>

  <script>
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput,
  VBTooltip,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMediaAside,
} from "bootstrap-vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import vSelect from "vue-select";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardHeader,
    AppCollapse,
    AppCollapseItem,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMediaAside,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    VBTooltip,
    BButton,
    BAvatar,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {
    const GENAPP_APP_STORE_MODULE_NAME = "reports";
    const modulename = "Reports";
    const modulenamesub = "reports";

    const appData =  [{
            title: "navigation.school",
            name: "reports-children-school",
            icon: "TruckIcon",
          },
          {
            title: "navigation.vaccination",
            name: "reports-children-vaccination",
            icon: "TruckIcon",
          },



    ];
    return {
      modulename,
      modulenamesub,
      appData,
      GENAPP_APP_STORE_MODULE_NAME,
    };
  },
  methods: {},
};
</script>

  <style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.electricity-filter-select {
  min-width: 190px;
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
